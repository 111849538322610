import React, { useState, useEffect } from "react";
import { Button, message } from "antd";
import { StopOutlined } from "@ant-design/icons";
import fetchData from "../services/requester";

const RequestCancel = (props) => {
  const [isLoading, setLoading] = useState(false);
  const [isWarning, setWarning] = useState(false);

  const onFinish = () => {
    setLoading(true);
    let reqURL = "requests/cancel";
    let reqOBJ = {};
    reqOBJ.requestId = props?.requestId;
    reqOBJ.isCancellationChargeable = isWarning;

    fetchData(reqURL, reqOBJ, (data) => {
      setLoading(false);
      if (data?.success) {
        message.success("Request cancelled successfully.");
      } else {
        message.error(data || "Oops! Something went wrong.");
      }
      props.handleCancel();
    });
  };

  const checkCancellationWarning = () => {
    const pickupDateTime = new Date(props?.pickupDateTime);
    const currentTime = new Date();
    const timeDiff = pickupDateTime - currentTime;
    const hoursDiff = timeDiff / (1000 * 60 * 60);

    props?.status === "Confirmed" && setWarning(hoursDiff <= 12);
  };

  useEffect(() => {
    checkCancellationWarning();
  }, [props?.pickupDateTime]);

  return (
    <React.Fragment>
      <h6 className="mb-4">Are you sure to cancel this request?</h6>

      {isWarning && (
        <p style={{ color: "red" }}>
          Cancellation charges applicable as you are cancelling the request
          within 12 hours of the pickup date time.
        </p>
      )}

      <Button
        icon={<StopOutlined />}
        type="primary"
        danger
        loading={isLoading}
        onClick={onFinish}
      >
        Confirm Cancel
      </Button>
    </React.Fragment>
  );
};

export default RequestCancel;
