import React, { useState } from "react";
import { Form, Input, Button, Typography, Select, message } from "antd";
import { useNavigate } from "react-router-dom";
import fetchData from "../services/requester";

const Signup = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { Title } = Typography;

  const layout = {
    labelCol: {
      span: 8,
    },
    wrapperCol: {
      span: 16,
    },
  };

  const onFinish = (values) => {
    setLoading(true);
    let reqURL = "signup";
    let reqOBJ = values;

    // Setup Default Values
    reqOBJ.currency = "INR";
    reqOBJ.language = "en-US";
    reqOBJ.template = "Template1";
    reqOBJ.url = values?.company?.replace(/[^a-z0-9-]/gi, "").toLowerCase();

    fetchData(reqURL, reqOBJ, (data) => {
      setLoading(false);
      if (data?.success) {
        message.success(
          "Sign up successful! Please sign in to access your account. Welcome!"
        );
        navigate("/signin");
      } else {
        message.error(data || "Something went wrong");
      }
    });
  };

  const handleSignin = () => {
    navigate("/signin");
  };

  return (
    <div className="signin-box signup-box">
      <Title level={4} className="signin-box-title">
        Sign up
      </Title>
      <Form
        name="signup"
        onFinish={onFinish}
        autoComplete="off"
        className="signin-form"
        {...layout}
        initialValues={{
          countryCode: "+91",
        }}
      >
        <Form.Item
          label="Your Name"
          name="name"
          rules={[
            {
              required: true,
              message: "Please input First and Last Name!",
            },
          ]}
        >
          <Input placeholder="First and Last Name" />
        </Form.Item>
        <Form.Item
          label="Email Id"
          name="email"
          rules={[
            {
              type: "email",
              required: true,
              message: "Please input Email Id!",
            },
          ]}
        >
          <Input placeholder="Enter Email Id" />
        </Form.Item>
        <Form.Item
          label="Phone Number"
          name="phone"
          rules={[
            {
              required: true,
              message: "Please input Phone Number!",
            },
          ]}
        >
          <Input
            addonBefore={
              <Form.Item name="countryCode" noStyle>
                <Select style={{ width: 70 }}>
                  <Select.Option value="+91">+91</Select.Option>
                </Select>
              </Form.Item>
            }
            placeholder="Enter Phone Number"
          />
        </Form.Item>
        <Form.Item
          label="Create Password"
          name="password"
          rules={[
            {
              required: true,
              message: "Please input Password!",
            },
          ]}
        >
          <Input type="password" placeholder="Enter Password" />
        </Form.Item>
        <Form.Item
          wrapperCol={{ xs: { offset: 0 }, xl: { offset: 8 } }}
          className="btn-row"
        >
          <Button type="primary" htmlType="submit" loading={loading}>
            Sign up
          </Button>
          <Button type="default" onClick={handleSignin}>
            Sign in
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default Signup;
