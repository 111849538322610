import React, { useEffect, useState } from "react";
import fetchData from "../services/requester";
import { Button, Table, Drawer } from "antd";
import { PlusOutlined, MergeCellsOutlined } from "@ant-design/icons";
import SupplierAdd from "./supplier-add";
import SupplierMapping from "./supplier-mapping";

const Suppliers = () => {
  const [data, setData] = useState([]);
  const [details, setDetails] = useState("");
  const [loading, setLoading] = useState(true);
  const [isAddSupplier, setIsAddSupplier] = useState(false);
  const [isMapping, setIsMapping] = useState(false);

  const getData = () => {
    let reqURL = "suppliers";
    let reqOBJ = "";
    fetchData(
      reqURL,
      reqOBJ,
      (data) => {
        setData(data || []);
        setLoading(false);
      },
      "GET"
    );
  };

  const getColumns = () => {
    let columns = [
      {
        title: "Supplier Id",
        dataIndex: "supplierId",
        key: "supplierId",
        width: 1,
      },
      {
        title: "Supplier Name",
        dataIndex: "supplierName",
        key: "supplierName",
      },
      {
        title: "Email",
        dataIndex: "supplierEmail",
        key: "supplierEmail",
      },
      {
        title: "Phone",
        dataIndex: "supplierPhone",
        key: "supplierPhone",
      },
      {
        title: "Markup",
        dataIndex: "supplierMarkup",
        key: "supplierMarkup",
        render: (text) => text + "%",
        width: 1,
      },
      {
        key: "actions",
        width: 1,
        render: (text, record) => (
          <Button
            color="primary"
            variant="link"
            className="reset-icon-btn"
            onClick={() => handleEdit(record)}
          >
            Edit
          </Button>
        ),
      },
    ];

    return columns;
  };

  const handleEdit = (record) => {
    setDetails(record);
    setIsAddSupplier(true);
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div>
      <div style={{ position: "absolute", right: "16px", top: "12px" }}>
        <Button
          type="default"
          icon={<MergeCellsOutlined />}
          onClick={() => setIsMapping(true)}
          style={{ marginRight: "12px" }}
        >
          Supplier Mapping
        </Button>

        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={() => setIsAddSupplier(true)}
        >
          Add Supplier
        </Button>
      </div>

      <Table
        columns={getColumns()}
        rowKey={(record) => record?.supplierId}
        dataSource={data || []}
        loading={loading}
        pagination={false}
        bordered
        scroll={{ x: 400 }}
      />

      <Drawer
        title={(details?.supplierId ? "Update" : "Add") + " Supplier"}
        placement="right"
        onClose={() => {
          setIsAddSupplier(false);
          setDetails("");
        }}
        open={isAddSupplier}
        width={600}
      >
        <SupplierAdd
          {...details}
          key={isAddSupplier}
          handleAddSupplier={() => {
            setIsAddSupplier(false);
            setDetails("");
            getData();
          }}
        />
      </Drawer>

      <Drawer
        title="Supplier Mapping"
        placement="right"
        onClose={() => setIsMapping(false)}
        open={isMapping}
        width={600}
      >
        <SupplierMapping handleUpload={() => setIsMapping(false)} />
      </Drawer>
    </div>
  );
};

export default Suppliers;
