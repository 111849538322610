import config from "../config.json";

const appPath =
  config?.mode === "dev"
    ? config.devPath
    : config?.mode === "staging"
    ? config.stagingPath
    : config.livePath;

const fetchData = (reqURL, reqOBJ, callback, reqMethod = "POST") => {
  return fetch(appPath + reqURL, {
    method: reqMethod,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
    body: reqOBJ ? JSON.stringify(reqOBJ) : null,
  })
    .then((res) => {
      if (res?.status === 401) {
        localStorage.removeItem("token");
        localStorage.removeItem("role");
        localStorage.removeItem("name");
        window.location.href = "/signin";
        return Promise.reject("Session expired");
      }

      return res?.text()?.then((data) => {
        if (!data || data === "") {
          callback({ success: true });
        } else {
          try {
            callback(JSON.parse(data));
          } catch (e) {
            callback(data);
          }
        }
      });
    })
    .catch((e) => {
      console.error("Fetch error:", e);

      if (e instanceof TypeError) {
        console.log("Service is unavailable.");
      } else {
        console.log("An unexpected error occurred. Please try again later.");
      }

      callback({ success: false, message: e.message || "Network error" });
    });
};

export default fetchData;
