import React, { useState, useEffect } from "react";
import fetchData from "../services/requester";
import * as XLSX from "xlsx";
import { CloudUploadOutlined, CloudDownloadOutlined } from "@ant-design/icons";
import { Button, Table, Upload, message } from "antd";

const SupplierMapping = (props) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isUploading, setIsUploading] = useState(false);

  const getData = () => {
    let reqURL = "suppliers/supplier-mappings";
    let reqOBJ = "";
    fetchData(
      reqURL,
      reqOBJ,
      (data) => {
        setData(data || []);
        setLoading(false);
      },
      "GET"
    );
  };

  const getColumns = () => {
    let columns = [
      {
        title: "Supplier Id",
        dataIndex: "supplierId",
        key: "supplierId",
        width: 1,
      },
      {
        title: "State",
        dataIndex: "stateName",
        key: "stateName",
      },
      {
        title: "City",
        dataIndex: "cityName",
        key: "cityName",
      },
    ];

    return columns;
  };

  const handleDownload = () => {
    const exportData = data?.map(({ stateName, cityName, supplierId }) => ({
      StateName: stateName,
      CityName: cityName,
      SupplierId: supplierId,
    }));

    const ws = XLSX.utils.json_to_sheet(exportData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Suppliers");

    XLSX.writeFile(wb, "suppliers_mapping.xlsx");
  };

  const handleFileUpload = (file) => {
    setIsUploading(true);
    const reader = new FileReader();
    reader.onload = (e) => {
      const ab = e.target.result;
      const wb = XLSX.read(ab, { type: "array" });
      const ws = wb.Sheets[wb.SheetNames[0]];
      const jsonData = XLSX.utils.sheet_to_json(ws);

      const formattedData = jsonData.map((item, key) => ({
        supplierMappingId: key + 1,
        stateName: item["StateName"],
        cityName: item["CityName"],
        supplierId: item["SupplierId"],
      }));

      setData(formattedData);
      handleImport(formattedData);
    };

    reader.readAsArrayBuffer(file);
    return false;
  };

  const handleImport = (formattedData) => {
    let reqURL = "suppliers/import";
    let reqOBJ = formattedData;

    fetchData(reqURL, reqOBJ, (data) => {
      if (data?.success) {
        message.success("Supplier mapping updated successfully.");
        props.handleUpload();
      } else {
        message.error("Oops! Something went wrong.");
      }
      setIsUploading(false);
    });
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div>
      <div style={{ position: "absolute", right: "16px", top: "12px" }}>
        <Button
          type="default"
          icon={<CloudDownloadOutlined />}
          style={{ marginRight: "12px" }}
          onClick={handleDownload}
        >
          Donwload
        </Button>

        <Upload
          accept=".xlsx,.xls"
          beforeUpload={handleFileUpload}
          showUploadList={false}
        >
          <Button
            type="primary"
            loading={isUploading}
            icon={<CloudUploadOutlined />}
          >
            Upload
          </Button>
        </Upload>
      </div>

      <Table
        columns={getColumns()}
        rowKey={(record) => record?.supplierMappingId}
        dataSource={data || []}
        loading={loading}
        pagination={false}
        bordered
        scroll={{ x: 400 }}
        size="small"
      />
    </div>
  );
};

export default SupplierMapping;
