import React from "react";
import { useNavigate } from "react-router-dom";
import { Button, Layout } from "antd";
import Logo from "../assets/images/logo.png";
import { Link } from "react-router-dom";

const AppHeader = (props) => {
  const { Header } = Layout;
  const navigate = useNavigate();
  const name = localStorage.getItem("name") || "";
  const host = window.location.hostname;

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("role");
    localStorage.removeItem("name");
    navigate("/signin");
  };

  return (
    <Header className="public-header" style={{ paddingLeft: "20px" }}>
      <Link className="logo" to="/">
        <img src={Logo} alt="Travelebrium" />
      </Link>

      {!props?.isSignedIn && (
        <React.Fragment>
          {host === "customer.travelebrium.com" && (
            <div className="public-header-menu">
              <Link to="/signin">Sign in</Link>
              <Link to="/signup">Sign up</Link>
            </div>
          )}
        </React.Fragment>
      )}

      {props?.isSignedIn && (
        <div className="public-header-menu">
          <b>{name}</b>
          <Button type="link" onClick={handleLogout}>
            Logout
          </Button>
        </div>
      )}
    </Header>
  );
};

export default AppHeader;
