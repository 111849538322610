import React, { useEffect, useState } from "react";
import { Table, Button, Tag, Tooltip, Drawer, Spin } from "antd";
import { PageHeader } from "@ant-design/pro-layout";
import { PlusOutlined, ExportOutlined, GiftOutlined } from "@ant-design/icons";
import { ExclamationCircleOutlined, TeamOutlined } from "@ant-design/icons";
import { FilterOutlined } from "@ant-design/icons";
import fetchData from "../services/requester";
import { DateTime, Currency } from "../components/formatter";
import DriverDetails from "../components/driver-details";
import QuotationAdd from "../components/quotation-add";
import DenyRequest from "../components/deny-request";
import AddRequest from "../components/add-request";
import RequestApproval from "../components/request-approval";
import Suppliers from "../components/suppliers";
import RequestCancel from "../components/request-cancel";
import ExcelExport from "../components/excel-export";
import ExpenseAdd from "../components/expence-add";
import MakeComplimentary from "../components/make-complimentary";
import Filters from "../components/filters";

const Dashboard = () => {
  const [role, setRole] = useState(localStorage.getItem("role"));
  const [data, setData] = useState([]);
  const [defaultData, setDefaultData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [details, setDetails] = useState();
  const [isRequest, setIsRequest] = useState(false);
  const [isApprove, setIsApprove] = useState(false);
  const [isQuotation, setIsQuotation] = useState(false);
  const [isDeny, setIsDeny] = useState(false);
  const [isDriverDetails, setIsDriverDetails] = useState(false);
  const [isCancel, setIsCancel] = useState(false);
  const [isSuppliers, setIsSuppliers] = useState(false);
  const [isConfirm, setIsConfirm] = useState(false);
  const [isExport, setIsExport] = useState(false);
  const [isExpense, setIsExpense] = useState(false);
  const [isComplimentary, setIsComplimentary] = useState(false);
  const [pageLoading, setPageLoading] = useState(true);
  const [isFilters, setIsFilters] = useState(false);

  const getData = () => {
    let reqURL = "requests";
    let reqOBJ = "";
    fetchData(
      reqURL,
      reqOBJ,
      (data) => {
        setData(data || []);
        setDefaultData(data || []);
        setLoading(false);
        setPageLoading(false);
      },
      "GET"
    );
  };

  const getColumns = () => {
    let role = localStorage.getItem("role");

    let columns = [
      {
        title: "Request Date",
        dataIndex: "requestDate",
        key: "requestDate",
        render: (text, record) => (
          <div>
            <div className="small text-secondary">#{record?.requestNumber}</div>
            <div className="text-nowrap">{DateTime(text)}</div>
          </div>
        ),
        width: 1,
      },
      {
        title: "Customer",
        dataIndex: "name",
        key: "name",
      },
      {
        title: "Supplier",
        dataIndex: "supplierName",
        key: "supplierName",
      },
      {
        title: "Pickup Date & Time",
        dataIndex: "pickupDateTime",
        key: "pickupDateTime",
        render: (text) => DateTime(text),
        width: 1,
      },
      {
        title: "State & City",
        dataIndex: "city",
        key: "city",
        render: (text, record) => (
          <div>
            <div className="small text-secondary">{record?.state}</div>
            <div>{text}</div>
          </div>
        ),
      },
      {
        title: "Pickup from",
        dataIndex: "pickupFrom",
        key: "pickupFrom",
      },
      {
        title: "Drop to",
        dataIndex: "dropTo",
        key: "dropTo",
      },
      {
        title: "Travellers & Bags",
        dataIndex: "noOfTravellers",
        key: "noOfTravellers",
        render: (text, record) => (
          <React.Fragment>
            <div>
              {record?.noOfTravellers} Travellers - {record?.noOfBags} Bags -{" "}
              {record?.vehicleType} - {record?.distance}km
            </div>
            {record?.travellerName && (
              <div>
                {record?.travellerTitle} {record?.travellerName} -{" "}
                {record?.travellerPhone}
              </div>
            )}
            <span className="small text-secondary">{record?.comments}</span>
          </React.Fragment>
        ),
      },
      {
        title: "Quote",
        dataIndex: "quoteFromSupplier",
        key: "quoteFromSupplier",
        render: (text, record) =>
          text ? (
            <div>
              <div className="small text-secondary">
                {DateTime(record?.quotationDate)}
              </div>
              <span className="text-nowrap">{Currency(text)}</span>
              {record?.expensetoSupplier && (
                <div style={{ display: "flex" }} className="text-secondary">
                  {Currency(record?.expensetoSupplier)}

                  <Tooltip
                    title={"Misc Expense: " + record?.expenseType}
                    placement="bottomRight"
                  >
                    <Button
                      className="reset-icon-btn"
                      icon={<ExclamationCircleOutlined />}
                    />
                  </Tooltip>
                </div>
              )}
            </div>
          ) : (
            record.status !== "Cancelled" &&
            record.status !== "Denied" && (
              <Button
                type="primary"
                onClick={() => handleAction("quotation", record)}
              >
                Add Quote
              </Button>
            )
          ),
      },
      {
        title: "Quote",
        dataIndex: "quoteToClient",
        key: "quoteToClient",
        render: (text, record) =>
          text && (
            <div>
              <div className="small text-secondary">
                {DateTime(record?.quotationDate)}
              </div>
              <div className="text-nowrap">{Currency(text)}</div>
              {record?.expensetoClient && (
                <div style={{ display: "flex" }} className="text-secondary">
                  {Currency(record?.expensetoClient)}{" "}
                  <Tooltip
                    title={"Misc Expense: " + record?.expenseType}
                    placement="bottomRight"
                  >
                    <Button
                      className="reset-icon-btn"
                      icon={<ExclamationCircleOutlined />}
                    />
                  </Tooltip>
                </div>
              )}

              {record?.status === "Quotation" && (
                <Button
                  type="primary"
                  className="mt-1"
                  onClick={() => handleAction("confirm", record)}
                >
                  Confirm
                </Button>
              )}
            </div>
          ),
      },
      {
        title: "Quote",
        dataIndex: "quoteFromSupplier",
        key: "quoteForAdmin",
        render: (text, record) =>
          text && (
            <div className="text-nowrap">
              <div className="small text-secondary">
                {DateTime(record?.quotationDate)}
              </div>
              {Currency(record?.quoteToClient)}
              <div className="text-secondary small">
                {Currency(record?.quoteFromSupplier)} +{" "}
                {Currency(record?.quoteMarkup)}
              </div>
            </div>
          ),
      },
      {
        title: "Expense",
        key: "expensetoAdmin",
        render: (text, record) => (
          <div className="text-nowrap">
            {record?.expensetoClient ? (
              <div>
                {Currency(record?.expensetoClient)}{" "}
                <span className="small text-secondary">(Client)</span>
              </div>
            ) : (
              ""
            )}

            {record?.expensetoSupplier ? (
              <div>
                {Currency(record?.expensetoSupplier)}{" "}
                <span className="small text-secondary">(Supplier)</span>
              </div>
            ) : (
              ""
            )}

            {record?.status === "Confirmed" && (
              <React.Fragment>
                {record?.expensetoClient ||
                record?.expensetoSupplier ||
                record?.expenseType ? (
                  <Button
                    type="link"
                    size="small"
                    className="reset-icon-btn"
                    onClick={() => handleAction("expense", record)}
                  >
                    Update
                  </Button>
                ) : (
                  <Button
                    type="default"
                    onClick={() => handleAction("expense", record)}
                    className="mt-1"
                  >
                    Add
                  </Button>
                )}
              </React.Fragment>
            )}
          </div>
        ),
      },
      {
        title: "BRN",
        dataIndex: "bookingConfirmationNumber",
        key: "bookingConfirmationNumber",
        width: 92,
        render: (text, record) => (
          <div>
            <div className="small text-secondary">{record?.bookingId}</div>
            <div style={{ color: "green" }}>{text}</div>
          </div>
        ),
      },
      {
        title: "Driver Details",
        dataIndex: "driverName",
        key: "driverDetails",
        render: (text, record) =>
          text && (
            <React.Fragment>
              {record?.driverName} - {record?.driverPhone} <br />
              {record?.vehicleDetails}
            </React.Fragment>
          ),
      },
      {
        title: "Driver Details",
        dataIndex: "driverName",
        key: "driverDetailsforSupplier",
        width: 142,
        render: (text, record) =>
          text ? (
            <div>
              {text}
              {record?.status !== "Cancelled" && (
                <Button
                  type="link"
                  size="small"
                  className="reset-icon-btn"
                  onClick={() => handleAction("driver", record)}
                >
                  Update Details
                </Button>
              )}
            </div>
          ) : (
            record?.status === "Confirmed" && (
              <Button
                type="primary"
                onClick={() => handleAction("driver", record)}
              >
                Add Details
              </Button>
            )
          ),
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        width: 108,
        render: (text, record) => (
          <div>
            <div className="small text-secondary text-nowrap mb-1">
              {record?.confirmationDate && DateTime(record?.confirmationDate)}
            </div>
            {getStatusTag(text, record)}
          </div>
        ),
      },
      {
        title: "Actions",
        key: "customerActions",
        width: 1,
        render: (text, record) =>
          record.status !== "Cancelled" &&
          record.status !== "Denied" && (
            <Button onClick={() => handleAction("cancel", record)}>
              Cancel
            </Button>
          ),
      },
      {
        title: "Actions",
        key: "supplierActions",
        width: 1,
        render: (text, record) =>
          record.status !== "Cancelled" &&
          record.status !== "Denied" && (
            <div style={{ display: "flex" }}>
              {record?.status === "Approved" && (
                <Button
                  type="primary"
                  onClick={() => handleAction("confirm", record)}
                  style={{ marginRight: "8px" }}
                >
                  Confirm
                </Button>
              )}

              {record?.status !== "Confirmed" && (
                <Button onClick={() => handleAction("deny", record)}>
                  Deny
                </Button>
              )}
            </div>
          ),
      },
      {
        title: "Actions",
        key: "adminActions",
        width: 1,
        align: "center",
        render: (text, record) =>
          record?.status === "Confirmed" &&
          !record?.isComplimentary && (
            <Tooltip title={"Make Complimentary"} placement="bottomRight">
              <Button
                color="default"
                onClick={() => handleAction("complimentary", record)}
                icon={<GiftOutlined />}
              />
            </Tooltip>
          ),
      },
    ];

    if (role === "Customer") {
      return columns.filter(
        (x) =>
          x.key !== "quoteForAdmin" &&
          x.key !== "quoteFromSupplier" &&
          x.key !== "supplierName" &&
          x.key !== "name" &&
          x.key !== "driverDetailsforSupplier" &&
          x.key !== "supplierActions" &&
          x.key !== "expensetoSupplier" &&
          x.key !== "expensetoAdmin" &&
          x.key !== "state" &&
          x.key !== "adminActions"
      );
    }

    if (role === "Supplier") {
      return columns.filter(
        (x) =>
          x.key !== "quoteForAdmin" &&
          x.key !== "quoteToClient" &&
          x.key !== "supplierName" &&
          x.key !== "name" &&
          x.key !== "driverDetails" &&
          x.key !== "customerActions" &&
          x.key !== "expensetoClient" &&
          x.key !== "expensetoAdmin" &&
          x.key !== "state" &&
          x.key !== "adminActions"
      );
    }

    if (role === "Admin") {
      return columns.filter(
        (x) =>
          x.key !== "quoteFromSupplier" &&
          x.key !== "quoteToClient" &&
          x.key !== "driverDetailsforSupplier" &&
          x.key !== "supplierActions" &&
          x.key !== "customerActions" &&
          x.key !== "expensetoClient" &&
          x.key !== "expensetoSupplier" &&
          x.key !== "state" &&
          x.key !== "noOfTravellers"
      );
    }

    return columns;
  };

  const getStatusTag = (text, record) => {
    let tag = "";

    switch (text) {
      case "New":
        tag = <Tag color="blue">{text}</Tag>;
        break;

      case "Quotation":
        tag = <Tag color="lime">{text}</Tag>;
        break;

      case "Approved":
        tag = <Tag color="green">{text}</Tag>;
        break;

      case "Confirmed":
        tag = <Tag color="purple">{text}</Tag>;
        break;

      case "Booked":
        tag = <Tag color="gold">{text}</Tag>;
        break;

      case "Cancelled":
        tag = (
          <div style={{ display: "flex" }}>
            <Tag color="volcano">{text}</Tag>
            {record?.isCancellationChargeable && (
              <Tooltip title="Chargeable" placement="bottomRight">
                <Button
                  className="reset-icon-btn"
                  style={{ marginLeft: "-8px" }}
                  icon={<ExclamationCircleOutlined />}
                />
              </Tooltip>
            )}
          </div>
        );
        break;

      case "Denied":
        tag = (
          <div style={{ display: "flex" }}>
            <Tag color="orange">{text}</Tag>
            {record?.reasonForDenied && (
              <Tooltip title={record?.reasonForDenied} placement="bottomRight">
                <Button
                  className="reset-icon-btn"
                  icon={<ExclamationCircleOutlined />}
                />
              </Tooltip>
            )}
          </div>
        );
        break;

      default:
        break;
    }

    return tag;
  };

  const handleAction = (key, item) => {
    setDetails(item);

    switch (key) {
      case "add-request":
        setIsRequest(true);
        break;

      case "approval":
        setIsApprove(true);
        break;

      case "cancel":
        setIsCancel(true);
        break;

      case "quotation":
        setIsQuotation(true);
        break;

      case "confirm":
        setIsConfirm(true);
        break;

      case "driver":
        setIsDriverDetails(true);
        break;

      case "deny":
        setIsDeny(true);
        break;

      case "suppliers":
        setIsSuppliers(true);
        break;

      case "export":
        setIsExport(true);
        break;

      case "expense":
        setIsExpense(true);
        break;

      case "complimentary":
        setIsComplimentary(true);
        break;

      case "filters":
        setIsFilters(true);
        break;

      default:
        break;
    }
  };

  const handleFilters = (data) => {
    setData(data || []);
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className="mt-1">
      <PageHeader
        title={"Dashboard"}
        extra={[
          <Button
            key="export"
            type="default"
            icon={<FilterOutlined />}
            onClick={() => handleAction("filters")}
            style={{ marginRight: "8px" }}
          >
            Filters
          </Button>,
          <Button
            key="export"
            type="default"
            icon={<ExportOutlined />}
            onClick={() => handleAction("export")}
            style={{ marginRight: "8px" }}
          >
            Export
          </Button>,
          role === "Customer" && (
            <Button
              key="add-request"
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => handleAction("add-request")}
            >
              Add Request
            </Button>
          ),
          role === "Admin" && (
            <React.Fragment>
              <Button
                key="suppliers"
                type="primary"
                icon={<TeamOutlined />}
                onClick={() => handleAction("suppliers")}
              >
                Suppliers
              </Button>
            </React.Fragment>
          ),
        ].filter(Boolean)}
        ghost={false}
        style={{ border: "0px" }}
      />

      <Table
        columns={getColumns()}
        rowKey={(record) => record?.requestId}
        dataSource={data || []}
        loading={loading}
        pagination={false}
        bordered
        scroll={{ x: 400 }}
        size="small"
      />

      <Drawer
        title="Add Request"
        placement="right"
        onClose={() => setIsRequest(false)}
        open={isRequest}
        size={"large"}
      >
        <AddRequest
          key={isRequest}
          handleAdd={() => {
            setIsRequest(false);
            getData();
          }}
        />
      </Drawer>

      <Drawer
        title="Confirm Request"
        placement="right"
        onClose={() => setIsConfirm(false)}
        open={isConfirm}
      >
        <RequestApproval
          {...details}
          key={isConfirm}
          handleConfirm={() => {
            setIsConfirm(false);
            getData();
          }}
        />
      </Drawer>

      <Drawer
        title="Add Quote"
        placement="right"
        onClose={() => setIsQuotation(false)}
        open={isQuotation}
      >
        <QuotationAdd
          {...details}
          key={isQuotation}
          handleQuotation={() => {
            setIsQuotation(false);
            getData();
          }}
        />
      </Drawer>

      <Drawer
        title={(details?.driverName ? "Update" : "Add") + " Driver Details"}
        placement="right"
        onClose={() => setIsDriverDetails(false)}
        open={isDriverDetails}
        width={480}
      >
        <DriverDetails
          {...details}
          key={isDriverDetails}
          handleDriverDetails={() => {
            setIsDriverDetails(false);
            getData();
          }}
        />
      </Drawer>

      <Drawer
        title="Deny Request"
        placement="right"
        onClose={() => setIsDeny(false)}
        open={isDeny}
      >
        <DenyRequest
          {...details}
          key={isDeny}
          handleDeny={() => {
            setIsDeny(false);
            getData();
          }}
        />
      </Drawer>

      <Drawer
        title="Suppliers"
        placement="right"
        onClose={() => setIsSuppliers(false)}
        open={isSuppliers}
        size="large"
      >
        <Suppliers key={isSuppliers} />
      </Drawer>

      <Drawer
        title="Confirm Approve"
        placement="right"
        onClose={() => setIsCancel(false)}
        open={isCancel}
      >
        <RequestCancel
          {...details}
          key={isCancel}
          handleCancel={() => {
            setIsCancel(false);
            getData();
          }}
        />
      </Drawer>

      {/* <Drawer
        title="Confirm Request"
        placement="right"
        onClose={() => setIsConfirm(false)}
        open={isConfirm}
      >
        <RequestConfirm
          {...details}
          key={isConfirm}
          handleConfirm={() => {
            setIsConfirm(false);
            getData();
          }}
        />
      </Drawer> */}

      <Drawer
        title="Confirm Cancel"
        placement="right"
        onClose={() => setIsCancel(false)}
        open={isCancel}
      >
        <RequestCancel
          {...details}
          key={isCancel}
          handleCancel={() => {
            setIsCancel(false);
            getData();
          }}
        />
      </Drawer>

      <Drawer
        title="Excel Export"
        placement="right"
        onClose={() => setIsExport(false)}
        open={isExport}
      >
        <ExcelExport key={isExport} data={data} />
      </Drawer>

      <Drawer
        title={"Add or Update Expense"}
        placement="right"
        onClose={() => setIsExpense(false)}
        open={isExpense}
      >
        <ExpenseAdd
          {...details}
          key={isExpense}
          handleExpense={() => {
            setIsExpense(false);
            getData();
          }}
        />
      </Drawer>

      <Drawer
        title="Make Complimentary"
        placement="right"
        onClose={() => setIsComplimentary(false)}
        open={isComplimentary}
      >
        <MakeComplimentary
          {...details}
          key={isComplimentary}
          handleComplimentary={() => {
            setIsComplimentary(false);
            getData();
          }}
        />
      </Drawer>

      <Drawer
        title="Filters"
        placement="right"
        onClose={() => setIsFilters(false)}
        open={isFilters}
      >
        <Filters
          key={isFilters}
          data={defaultData}
          handleFilters={(data) => {
            setIsFilters(false);
            handleFilters(data);
          }}
        />
      </Drawer>

      {pageLoading && (
        <div className="page-loader">
          <Spin size="large" />
        </div>
      )}
    </div>
  );
};

export default Dashboard;
