import React, { useState } from "react";
import { Button, Select, DatePicker, Space, Typography } from "antd";
import { FilterOutlined } from "@ant-design/icons";
import moment from "moment";

const { RangePicker } = DatePicker;
const { Text } = Typography;

const Filters = (props) => {
  const { data } = props;
  const role = localStorage.getItem("role");
  const [isLoading, setLoading] = useState(false);
  const [selectedStatuses, setSelectedStatuses] = useState([]);
  const [requestDateRange, setRequestDateRange] = useState([null, null]);
  const [pickupDateTimeRange, setPickupDateTimeRange] = useState([null, null]);
  const [selectedSuppliers, setSelectedSuppliers] = useState([]);

  const uniqueStatuses = [...new Set(data.map((item) => item.status))];
  const uniqueSuppliers = [...new Set(data.map((item) => item.supplierName))];

  const handleExport = () => {
    const filteredData = data.filter((item) => {
      const isStatusValid =
        selectedStatuses.length === 0 ||
        selectedStatuses.includes(item?.status);

      const isSupplierValid =
        selectedSuppliers.length === 0 ||
        selectedSuppliers.includes(item?.supplierName);

      const isPickupDateValid =
        !pickupDateTimeRange[0]?.$d ||
        !pickupDateTimeRange[1]?.$d ||
        moment(item?.pickupDateTime).isBetween(
          moment(pickupDateTimeRange[0]?.$d),
          moment(pickupDateTimeRange[1]?.$d).add(1, "day"),
          null,
          "[]"
        );

      const isRequestDateValid =
        !requestDateRange[0]?.$d ||
        !requestDateRange[1]?.$d ||
        moment(item?.requestDate).isBetween(
          moment(requestDateRange[0]?.$d),
          moment(requestDateRange[1]?.$d).add(1, "day"),
          null,
          "[]"
        );

      return (
        isStatusValid &&
        isSupplierValid &&
        isPickupDateValid &&
        isRequestDateValid
      );
    });

    props?.handleFilters(filteredData);
  };

  return (
    <React.Fragment>
      <Space direction="vertical" style={{ width: "100%" }}>
        {role === "Admin" && (
          <div className="mt-1">
            <Text strong>Supplier:</Text>
            <Select
              mode="multiple"
              placeholder="Select Supplier"
              value={selectedSuppliers}
              onChange={setSelectedSuppliers}
              style={{ width: "100%" }}
            >
              {uniqueSuppliers.map((supplier) => (
                <Select.Option key={supplier} value={supplier}>
                  {supplier}
                </Select.Option>
              ))}
            </Select>
          </div>
        )}

        <div>
          <Text strong>Status:</Text>
          <Select
            mode="multiple"
            placeholder="Select Status"
            value={selectedStatuses}
            onChange={setSelectedStatuses}
            style={{ width: "100%" }}
          >
            {uniqueStatuses.map((status) => (
              <Select.Option key={status} value={status}>
                {status}
              </Select.Option>
            ))}
          </Select>
        </div>

        <div className="mt-1">
          <Text strong>Pickup Date:</Text>
          <RangePicker
            placeholder={["Pickup Date From", "Pickup Date To"]}
            value={pickupDateTimeRange}
            onChange={setPickupDateTimeRange}
            style={{ width: "100%" }}
          />
        </div>

        <div className="mt-1 mb-3">
          <Text strong>Request Date:</Text>
          <RangePicker
            placeholder={["Request Date From", "Request Date To"]}
            value={requestDateRange}
            onChange={setRequestDateRange}
            style={{ width: "100%" }}
          />
        </div>

        <Button
          icon={<FilterOutlined />}
          type="primary"
          loading={isLoading}
          onClick={handleExport}
        >
          Apply Filters
        </Button>
      </Space>
    </React.Fragment>
  );
};

export default Filters;
